<!-- 默认调货周期-->
<template>
  <div style="margin-top: 10px">
    <el-row>
      <el-col style="padding-left: 25px">
        <h3 style="display: inline-block">默认调货周期设置</h3>
        <el-popover
            placement="right-start"
            width="300"
            trigger="hover">

          <i slot="reference" class="el-icon-question" style="font-size: 20px;color: #017ffd;margin-left: 10px"></i>
        </el-popover>
      </el-col>
    </el-row>
    <el-row style="margin-top: 15px;margin-bottom: 15px;">
      <el-col :span="20" :offset="1">
        <el-slider
            :max="30"
            v-model="rule"
            @change="change"
            show-input>
        </el-slider>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "operation-rule",
  created() {
    this.queryOperationRule()
  },
  data() {
    return {
      rule: null,
      tenantCrop: localStorage.getItem("tenantCrop"),
      operationRule:{
      },
    }
  },
  methods: {
    change() {
      this.$axios({
        method: "post",
        url: "/operationRule/updateOperationRule",
        data: {
          id: this.operationRule.id,
          rule: this.rule,
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? "修改成功" : response.data.msg,
          type: flag? 'success' :'error',
          duration: 500,
        })
        this.queryOperationRule()
      })
    },
    queryOperationRule() {
      this.$axios({
        method: "get",
        url: "/operationRule/queryOperationRule",
        params: {
          tenantCrop: localStorage.getItem("tenantCrop")
        }
      }).then(response => {
        this.rule = response.data.data.rule
        this.operationRule = response.data.data
      })
    }
  }
}
</script>

<style scoped>

</style>